<!-- 科研项目管理系统  1-->
<template>
  <div class="science">
    <banner-img :bannerImg="bannerImg" @goLink="goLink" />
    <cases-title :titleVal="'科研项目管理系统'" />
    <Business :business="business" />
    <!-- 应用场景 -->
    <div class="applications-container">
      <Subhead :enVal="'Applications  '" :znVal="'应用场景'" />
      <div class="app-text">{{ appText }}</div>
    </div>
  </div>
</template>

<script>
import bannerImg from "./components/BannerImg.vue";
import casesTitle from "./components/Title.vue";
import Business from "./components/Business.vue";
import Subhead from "./components/Subheading.vue";
export default {
  components: {
    bannerImg,
    casesTitle,
    Business,
    Subhead,
  },
  data() {
    return {
      bannerImg: require("@/assets/images/cases/banner/banner6.png"),
      business: {
        text: "随着科学事业的发展，我国在科研方面每年都需要投入大量的资金，支持和完成重大科研项目。如何规范管理科研项目的实施，保障国家重大科研项目顺利组织和开展，确保科研资金发挥成效，就显得非常重要。科研项目管理应该以项目管理为业务核心，涵盖项目申报、形式审查、线上评审、立项审批、签订合同、项目进展、项目变更、项目终止、验收结题、科研审批、科研登记、评奖和考核等业务流程和数据管理功能，实现规范化、精细化、科学化、信息化管理目标。通过建设科研项目管理系统，为科研项目的实施提供信息化管理平台支撑，以提升科研项目管理效率，加快科研项目审核、实施、经费使用进度，实时统计汇总分析，为科学决策提供依据，推动建立权责清晰、管理科学、运行高效、监督有力的科研项目管理工作制度。",
        Imgs: [
          {
            url: require("@/assets/images/cases/content/science01.png"),
          },
        ],
      },
      appText:
        "通过应用科研项目管理系统，对科研项目的信息数据进行规范统一录入、审核和归档管理，建立了规范的科研信息数据库，实现了科研项目的全流程管理。项目申报管理：申报需经过形式审查、科室推荐、专家评审、审批汇总等四个流程，填写申请书，新增项目申请，已立项项目查询等。项目评审管理：设置评审专家账号，对评审专家进行分组，专家可登陆系统实现在线评审。项目过程管理：系统设置合同书填报管理、进展报告、项目变更申请、项目终止申请、项目结题验收等功能。成果登记管理：管理科研成果，包括立项项目、发表论文、专利、论著、成果获奖、新技术获奖等科研成果登记，能根据各类科研登记栏目进行汇总统计，导出汇总表格。科研奖励管理：对要进行科研奖励的项目，按照相关政策，设置计算公式，系统可自动计算奖励金额数据。系统在广东省佛山市第一人民医院实施用于广东省高水平医院“登峰计划”科研项目管理，极大提高了管理效率，并扩展至医院全部科研项目的管理平台。",
    };
  },
  methods: {
    goLink() {
      window.location.href = "http://bioitbs.com:30001/login";
    },
  },
};
</script>

<style lang="less" scoped></style>
